<template>
  <div>
    <section id="card-text-alignment">
      <b-row>
        <!-- left align -->
        <b-col
          md="12"
          lg="12"
        >
          <b-card
            title=""
            class="mb-3"
          >
            <template>

              <!-- Table Container Card -->
              <b-card no-body>
                <template>
                  <div>
                    <!-- input search -->
                    <div class="custom-search d-flex justify-content-end">
                      <b-form-group>
                        <div class="d-flex align-items-center">
                          <label class="mr-1">Recherche
                          </label>
                          <b-form-input
                            v-model="searchTerm"
                            placeholder="Recherche"
                            type="text"
                            class="d-inline-block"
                          />
                        </div>
                      </b-form-group>
                      <!-- primary -->
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="ccms_btn"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Ajouter</span>
                      </b-button>
                    </div>

                    <!-- table -->
                    <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :rtl="direction"
                      :search-options="{
                        enabled: true,
                        externalQuery: searchTerm }"
                      :pagination-options="{
                        enabled: true,
                        perPage:pageLength
                      }"
                    >
                      <template
                        slot="table-row"
                        slot-scope="props"
                      >

                        <!-- Column: Name -->
                        <div
                          v-if="props.column.field === 'fullName'"
                          class="text-nowrap"
                        >
                          <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                          />
                          <span class="text-nowrap">{{ props.row.fullName }}</span>
                        </div>

                        <!-- Column: Status -->
                        <span v-else-if="props.column.field === 'status'">
                          <b-badge :variant="statusVariant(props.row.status)">
                            {{ props.row.status }}
                          </b-badge>
                        </span>

                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                          <span>
                            <b-dropdown
                              variant="link"
                              toggle-class="text-decoration-none"
                              no-caret
                            >
                              <template v-slot:button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="text-body align-middle mr-25"
                                />
                              </template>
                              <b-dropdown-item>
                                <feather-icon
                                  icon="Edit2Icon"
                                  class="mr-50"
                                />
                                <span>Edit</span>
                              </b-dropdown-item>
                              <b-dropdown-item>
                                <feather-icon
                                  icon="TrashIcon"
                                  class="mr-50"
                                />
                                <span>Delete</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>

                      <!-- pagination -->
                      <template
                        slot="pagination-bottom"
                        slot-scope="props"
                      >
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                              Affichage de 1 à
                            </span>
                            <b-form-select
                              v-model="pageLength"
                              :options="['3','5','10']"
                              class="mx-1"
                              @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> sur {{ props.total }} élements </span>
                          </div>
                          <div>
                            <b-pagination
                              :value="1"
                              :total-rows="props.total"
                              :per-page="pageLength"
                              first-number
                              last-number
                              align="right"
                              prev-class="prev-item"
                              next-class="next-item"
                              class="mt-1 mb-0"
                              @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                              <template #prev-text>
                                <feather-icon
                                  icon="ChevronLeftIcon"
                                  size="18"
                                />
                              </template>
                              <template #next-text>
                                <feather-icon
                                  icon="ChevronRightIcon"
                                  size="18"
                                />
                              </template>
                            </b-pagination>
                          </div>
                        </div>
                      </template>
                    </vue-good-table>
                  </div>
                </template>
              </b-card>

            </template>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BFormInput,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
// import axios from 'axios'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
export default {
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      date_debut: '',
      date_fin: '',
      formatted: '',
      selected: '',
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Date et Heure',
          field: 'date_hour',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher un date',
          },
        },
        {
          label: 'Ref Patient',
          field: 'id_patient',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher un patient',
          },
        },
        {
          label: 'Prénom',
          field: 'prenom',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher un prénom',
          },
        },
        {
          label: 'Nom',
          field: 'nom',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher un nom',
          },
        },
        {
          label: 'Adresse',
          field: 'adresse',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher une adresse',
          },
        },
        {
          label: 'Téléphone',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher un numéro',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/basic')
      .then(res => {
        this.rows = res.data
      })
  },
  methods: {
    reset() {
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
